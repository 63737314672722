import React, { FC } from 'react'
import { Text } from '../components'
import styled from 'styled-components';
import { LayoutPage } from '../layouts/LayoutPage';

const ImgWrapper = styled.div`
    position: relative;
    flex: 1;
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Message = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: var(--space-s) var(--space-s) var(--space-m) var(--space-s);
    text-align: center;
`;

const NotFoundPage: FC = () => (
    <LayoutPage>
        <ImgWrapper>
            <Img src="/404/snow.gif" alt="" draggable={false} />
            <Message>
                <Text fontSize="2.5rem" fontWeight="bold" color="white">404 ERROR</Text>
                {/*<Text fontSize="2.5rem" fontWeight="bold" color="white">-------</Text>*/}
                <Text fontSize="1.5rem" fontWeight="bold" color="white" whiteSpace="normal">This page does not exist ...</Text>
            </Message>
        </ImgWrapper>
    </LayoutPage>
);

export default NotFoundPage;
